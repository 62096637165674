import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/orders/customers/';

function list(params) {
  return storeRequest.get(path, { params: params });
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(customer) {
  return storeRequest.post(path, customer);
}

function update(customer) {
  return storeRequest.put(path, customer);
}

function updateInOrder(customer, orderId) {
  return storeRequest.put('/orders/' + orderId + '/customerInfo', customer);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}

export const customerService = {
  list,
  create,
  update,
  get,
  deleteById,
  updateInOrder,
};
