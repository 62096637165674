<template>
  <div>
    <v-row>
      <v-col cols="12">
        <redirect class="mb-5" title="Customers" route="website.customer" />
        <div class="page-header">
          <Notification v-if="error" :messages="error" type="error" />
          <h2 class="page-title">Add customer</h2>
        </div>
      </v-col>
      <v-col cols="12" class="elevation-form">
        <v-row>
          <v-col cols="12" md="4">
            <h3>Customer overview</h3>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="6">
                <InputDefault label="First name" :model="['customer', 'firstName']" />
              </v-col>
              <v-col cols="6">
                <InputDefault label="Last name" :model="['customer', 'lastName']" />
              </v-col>
              <v-col cols="12">
                <InputDefault :isValidate="true" label="Email" :model="['customer', 'email']" />
              </v-col>
              <v-col cols="5">
                <InputDefault
                  label="Country"
                  :model="['customer', 'country']"
                  typeComponent="select"
                  :itemsSelect="countries"
                  itemText="name"
                  itemValue="name"
                />
              </v-col>
              <v-col cols="7">
                <InputDefault label="Phone number" :model="['customer', 'phone']" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="4">
            <h3>Address</h3>
            <span>The primary address of this customer</span>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12">
                <InputDefault label="Address" :model="['customer', 'address1']" />
              </v-col>
              <v-col cols="12">
                <InputDefault label="Apartment, suite, etc... (optional)" :model="['customer', 'address2']" />
              </v-col>
              <v-col cols="12">
                <InputDefault label="City" :model="['customer', 'city']" />
              </v-col>
              <v-col cols="6">
                <InputDefault v-if="!provinces" label="Province" :model="['customer', 'province']" />
                <InputDefault
                  v-else
                  label="Province"
                  :model="['customer', 'province']"
                  typeComponent="select"
                  :itemsSelect="provinces"
                  itemText="name"
                  itemValue="name"
                />
              </v-col>
              <v-col cols="6">
                <InputDefault label="ZIP/Postal Code" :model="['customer', 'zip']" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn :loading="isLoading" :disabled="isLoading" @click="onSubmit" class="ml-auto" color="primary">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required, minLength, maxLength, minValue, maxValue, email, helpers, decimal } from 'vuelidate/lib/validators';
import { storeService } from '@/apis/store.s';
import { customerService } from '@/apis/customer';

export default {
  data() {
    return {
      customer: {
        email: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        province: 'Alabama',
        province_code: 'AL',
        country_code: 'US',
        country: 'United States',
        zip: '',
        phone: '',
      },
      error: null,
      isLoading: false,
      countries: [],
    };
  },
  validations: {
    customer: {
      email: {
        required,
        email,
      },
    },
  },
  created() {
    this.getCountries();
  },
  computed: {
    provinces() {
      let country = this.countries ? this.countries.find(c => c.name == this.customer.country) : null;
      return country ? country.provinces : null;
    },
  },
  methods: {
    async getCountries() {
      try {
        let countriesData = await storeService.getCountries();
        this.countries = countriesData.data;
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      if (!this.$v.$invalid) {
        try {
          let customerData = await customerService.create(this.customer);
          this.isLoading = false;

          this.$router.push({ name: 'website.customer.update', params: { id: customerData.data.id } });
        } catch (error) {
          this.error = error;
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
  watch: {
    'customer.country': function() {
      let country = this.countries ? this.countries.find(c => c.name == this.customer.country) : null;
      this.customer.country_code = country ? country.code : '';
    },
    'customer.province': function() {
      let province = this.provinces ? this.provinces.find(p => p.name == this.customer.province) : null;
      this.customer.province_code = province ? province.code : '';
    },
  },
};
</script>
